import { useEffect, useRef, useState } from 'react';
import {
  CListGroup,
  CListGroupItem,
  CBadge,
  CTooltip,
  CSpinner,
} from '@coreui/react';

function OptionList({
  setOptionsVisible,
  listOfOptions,
  handleChange,
  type,
}: {
  setOptionsVisible: any;
  listOfOptions: any[];
  handleChange: (type: string, option: string) => void;
  type: string;
}): JSX.Element {
  const optionListRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        optionListRef.current &&
        !optionListRef.current.contains(event.target as Node)
      ) {
        setOptionsVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      listOfOptions.length > 0 ||
      listOfOptions === undefined ||
      listOfOptions === null
    ) {
      setIsLoading(false);
    }
  }, [listOfOptions]);

  return (
    <div
      className={`border friendly-helper-options-list ${type}`}
      ref={optionListRef}>
      {isLoading ? (
        <div className="loading-status bg-white fs-6">
          <strong role="status">Loading. Please wait</strong>
          <CSpinner size="sm" className="ms-3" />
        </div>
      ) : (
        <div>
          {listOfOptions.length > 0 ? (
            <CListGroup flush>
              {listOfOptions.map((option) => (
                <CListGroupItem
                  key={option.display_name}
                  as="button"
                  onClick={(event) => {
                    event.preventDefault();
                    handleChange(type, option);
                  }}>
                  {type === 'application' && (
                    <CTooltip
                      content={
                        option.type === 'sp'
                          ? 'Back-end application'
                          : 'Front-end application'
                      }>
                      <CBadge
                        color={option.type === 'sp' ? 'success' : 'primary'}
                        className="me-2">
                        {option.type === 'sp' ? 'B' : 'F'}
                      </CBadge>
                    </CTooltip>
                  )}
                  {option.display_name}
                </CListGroupItem>
              ))}
            </CListGroup>
          ) : (
            <div className="bg-white fs-6 p-2">
              No {type === 'api' ? 'API' : type} found. If you cannot find your
              newly created consumer application, please contact us at
              #support-apim in Slack.
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default OptionList;
